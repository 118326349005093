import React, { useState } from "react";
import close from "../assets/close-btn.png";
import logo from "../assets/logo-mobile.svg";
import { isMobile } from "../App";
import { login } from "../api/api";
const LoginModal = ({ isOpen, onClose }) => {
  const [username, setUsername] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleClick = (value) => {
    console.log(username);
    login(username);
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        {isMobile === true ? (
          <div
            className="d-flex jcsb"
            style={{ width: "90%", margin: "auto", marginTop: "10px" }}
          >
            <img style={{ width: "auto", height: "auto" }} src={logo} alt="" />
            <span
              style={{ cursor: "pointer" }}
              className="close"
              onClick={onClose}
            >
              <img src={close} alt="" />
            </span>
          </div>
        ) : (
          <span
            style={{ position: "relative", right: "-90%", cursor: "pointer" }}
            onClick={onClose}
          >
            <img src={close} alt="" />
          </span>
        )}
        <div className="modal-content-text">
          <h1 className="modal-content-text_title">Join the Waitlist</h1>
          <p className="modal-content-text_sub">
            Be the first to know when we launch. Enter your email to join our
            waitlist.
          </p>
        </div>
        <div className="modal-content-join">
          <input
            onChange={handleUsernameChange}
            placeholder="name@mail.com"
            type="text"
            id="username"
            value={username}
            className="modal-content-join_input"
          />
          <button onClick={handleClick} className="modal-content-join_btn">
            Join Waitlist
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
