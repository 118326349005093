import React, { useState } from "react";
import { message } from "../api/api";

const Contact = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: ""
  });

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Check if fields are filled
    for (const key in formData) {
      if (formData[key].trim() === "") {
        newErrors[key] = "This field is required";
        valid = false;
      }
    }

    // Validate email format
    if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const send = (event) => {
    event.preventDefault();

    if (validateForm()) {
      message(formData)
    } else {
      console.log("Form validation failed");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    setErrors({})
  };

  return (
    <div className="contact">
      <div className="contact-main">
        <h1 className="contact-main_title">Have a question?</h1>
        <p className="contact-main_subtitle">Send us a message</p>
      </div>
      <form onSubmit={send} className="contact-form">
        <div className="contact-form-info">
          <input
            name="first_name"
            placeholder="First name"
            type="text"
            className={`contact-form-info_name ${errors.first_name ? "error" : ""}`}
            onChange={handleChange}
            value={formData.first_name}
          />
          <input
            name="last_name"
            placeholder="Last name"
            type="text"
            className={`contact-form-info_surname ${errors.last_name ? "error" : ""}`}
            onChange={handleChange}
            value={formData.last_name}
          />
        </div>
        <input
          name="email"
          placeholder="Email"
          type="text"
          className={`contact-form_email ${errors.email ? "error" : ""}`}
          onChange={handleChange}
          value={formData.email}
        />
        <textarea
          name="message"
          autoCapitalize="false"
          placeholder="Message"
          className={`contact-form_message ${errors.message ? "error" : ""}`}
          onChange={handleChange}
          value={formData.message}
        ></textarea>
        <button className="contact-form_btn" type="submit">Send</button>
      </form>
    </div>
  );
};

export default Contact;
