import axios from "axios";

const instance = axios.create({
  baseURL: "https://contact.payzap.io/",
});

export const login = async (value) => {
  try {
    const { data } = await instance("send-email/", {
      method: "POST",
      data: { email: value },
      headers: { "Content-Type": "application/json" },
    });
    console.log(data);
  } catch (error) {}
};

export const message = async ({first_name, last_name, email, message}) => {
  try {
    const { data } = await instance("send-messages/", {
      method: "POST",
      data: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        message: message,
      },
      headers: { "Content-Type": "application/json" },
    });
    console.log(data);
  } catch (error) {}
};
